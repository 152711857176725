import React from "react"
import Page from '../components/Page'
import Layout from "../components/layout"
import { useStoryblokState } from "gatsby-source-storyblok"

export default function PageIndex({ pageContext, location }) {
  const story = useStoryblokState(pageContext.story, location)
  const { content: { crumbLabel } } = story
  return (
    <Layout className="layoutclass" meta={{ ...story?.content?.Meta, tracking_variable: story?.content?.tracking_variable }} location={location} full_slug={story?.full_slug} lang={story?.lang} crumb={crumbLabel} pathname={pageContext.pathname}>
      <Page blok={story?.content} location={location} />
    </Layout>
  )
}
